import { LOGIN, LOGIN_FAIL, LOGOUT, START_LOADING, STOP_LOADING } from '../constants/action-types';

export function login(payload) {
    if(payload.username == 'admin' && payload.password == 'pwd123'){
        localStorage.setItem('loggedIn', 1);
        if(payload.remember){
            localStorage.setItem('username', payload.username);
            localStorage.setItem('password', payload.password);
        }
        else{
            localStorage.removeItem('username');
            localStorage.removeItem('password');
        }
        return {type: LOGIN, payload}
    }
    
    return {type: LOGIN_FAIL, payload};
};

export function logout(payload) {
    localStorage.setItem('loggedIn', 0);
    return {type: LOGOUT, payload}
}; 