function parseJSON(response) {
	return response.json();
}

function checkStatus(response) {
	if (response.status >= 200 && response.status < 300) {
		return response;
	}

	const error = new Error(response.statusText);
	error.response = response;
	throw error;
}

export default function request(url, method, data) {
	let requestOption = {
		method: method,
		headers : {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer 8q2kml0cx9esy6xfdou3cvib7p4ugdk2'
		}
	}

	const fullUrl = `https://dev9elvischoice.egusi.com.hk/${url}`;

	if (method !== 'GET'){
		requestOption['body'] = JSON.stringify(data);
	}

	return fetch(fullUrl, requestOption)
		// .then(checkStatus)
		.then(response => response.json())
		.then((data) => {
			return {data};
		})
		.catch(err => ({ err }));
}