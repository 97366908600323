import React, {Component} from "react";
import { withTranslation } from 'react-i18next';
import * as Actions from '../actions/index';
import actionConnector from '../utils/actionConnector';
import {Link, withRouter} from 'react-router-dom';
import { Row, Col, Button, Table, Input, Space, Pagination } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import request from '../utils/request';

class Orders extends Component{
    state = {
        searchText: '',
        searchedColumn: '',
        list: [],
        pagination: {
            current: 1,
            pageSize: 10,
            showTotal: (total, range) => `${range[0]}-${range[1]} / ${total}`,
            responsive: true
        },
        loading: false,
        width: window.innerWidth,
        height: window.innerHeight
    };

    componentDidMount(){
        this.updateOrderTable(1);
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    updateOrderTable = (current) => {
        const url = `rest/V1/orders?searchCriteria[filter_groups][0][filters][0][field]=store_id&searchCriteria[filter_groups][0][filters][0][value]=1&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[sortOrders][0][field]=created_at&searchCriteria[pageSize]=10&searchCriteria[currentPage]=${current}`;
        // this.props.loading(true);
        this.setState({
            loading: true
        });
        const fetchOrder = request(url, 'GET');
        fetchOrder.then((response) => {
            if('err' in response){
                
            }
            else{
                var dataItems = [];
                response.data.items.forEach((item)=>{
                    dataItems.push({
                        order_id: item.entity_id,
                        order_code: item.increment_id,
                        grand_total: `HK$${item.grand_total}`,
                        receiver: item.billing_address.firstname,
                        date: item.created_at,
                        status: item.status
                    });
                });
                this.setState(prevState => ({
                    list: dataItems,
                    pagination: {
                        ...prevState.pagination,
                        total: response.data.total_count,
                        current: current
                    }
                }));
            }
            // this.props.loading(false);
            this.setState({
                loading: false
            });
        });
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.updateOrderTable(pagination.current);
    };

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            this.setState({
                                searchText: selectedKeys[0],
                                searchedColumn: dataIndex,
                            });
                        }}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {this.props.i18n.t('Filter')}
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        {this.props.i18n.t('Reset')}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select(), 100);
          }
        },
        render: text =>
          this.state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[this.state.searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          ),
      });
    
    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };
    
    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    handleRowClick = record => {
        let path = `orders/${record.order_id}`;
        this.props.history.push(path);
    }

    render(){
        const { t } = this.props;
        const columns = [
            {
                title: t('Order ID'),
                dataIndex: 'order_code',
                key: 'order_code',
                ...this.getColumnSearchProps('order_code')
            },
            {
                title: t('Grand Total'),
                dataIndex: 'grand_total',
                key: 'grand_total',
                // ...this.getColumnSearchProps('grand_total')
            },
            {
                title: t('Receiver'),
                dataIndex: 'receiver',
                key: 'receiver',
                ...this.getColumnSearchProps('receiver')
            },
            {
                title: t('Order Date'),
                dataIndex: 'date',
                key: 'date',
                ...this.getColumnSearchProps('date')
            }
        ];
        return (
            <Row className="p-4 overflow-auto">
                <Col span={24}>
                    <Table className="order-table" loading={this.state.loading} dataSource={this.state.list} columns={columns} pagination={this.state.pagination} tableLayout="fixed" locale={{emptyText:t('No Data')}} onChange={this.handleTableChange} scroll={{ x: this.state.width < 576 ? 576 : null}} onRow={(record, rowIndex) => {
                        return {
                            onClick: (event) => this.handleRowClick(record)
                        };
                    }}/>
                </Col>
            </Row>  
        );
    }
}
export default actionConnector(withRouter(withTranslation()(Orders)), Object.assign(Actions));