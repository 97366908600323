import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import {Link, withRouter} from 'react-router-dom';
import {getRoute} from '../utils/routematch';
import { Menu, Dropdown, Typography, Row, Col} from 'antd';
import { CaretDownFilled } from '@ant-design/icons';
import * as Actions from '../actions/index';
import actionConnector from '../utils/actionConnector';

const { Title } = Typography;

class Nav extends Component{
    changeLanguage = (language) => {
        this.props.i18n.changeLanguage(language);
        localStorage.setItem('language', language);
    }

    logout = () => {
        this.props.logout();
    }

    render(){
        const { t, location } = this.props;
        const pathname = location.pathname;
        const menu = (
            <Menu className="text-center">
                <Menu.Item>
                    <a href="#" onClick={()=>this.changeLanguage('zh-cn')}>
                        <span className="d-block">{t('ZH-CN')}</span><span className="d-block tiny-tips">{t('Simplified Chinese')}</span>
                    </a>
                </Menu.Item>
                <Menu.Item>
                    <a href="#" onClick={()=>this.changeLanguage('zh')}>
                        <span className="d-block">{t('ZH')}</span><span className="d-block tiny-tips">{t('Traditional Chinese')}</span>
                    </a>
                </Menu.Item>
                <Menu.Item>
                    <a href="#" onClick={()=>this.changeLanguage('en')}>
                        <span className="d-block">{t('EN')}</span><span className="d-block tiny-tips">{t('English')}</span>
                    </a>
                </Menu.Item>
            </Menu>
        );
        return (
            <header className={`main-header shadow ant-layout-header p-0 ${this.props.fullNav ? '':'margin-left-header'}`}>
                <Row className="px-3">
                    <Col span={16}>
                        <Title level={3} className="text-white header-title">
                            {t(getRoute(pathname))}
                        </Title>
                    </Col>
                    <Col span={8} className="text-end">
                        {
                            this.props.reducer.loggedIn &&
                            <a className="me-3" href="#" onClick={()=>this.logout()}>{t('Logout')}</a>
                        }
                        <Dropdown overlay={menu} placement="bottomRight">
                            <a className="ant-dropdown-link fix-align-anticon" onClick={e => e.preventDefault()}>
                                {t('Languages')} <CaretDownFilled />
                            </a>
                        </Dropdown>
                    </Col>
                </Row>
            </header>
        );
    }
}
// export default withRouter(withTranslation()(Nav));
export default actionConnector(withRouter(withTranslation()(Nav)), Object.assign(Actions));