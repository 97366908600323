import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import * as Actions from '../actions/index';
import actionConnector from '../utils/actionConnector';
import { Form, Input, Button, Checkbox, Row, Col, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import {
    LOGIN_FAIL
} from '../constants/action-types';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

class Login extends Component{
    onFinish = (values) => {
        console.log('Success:', values);
        const login = this.props.login(values);
        if(login.type == LOGIN_FAIL){
            message.error(this.props.i18n.t('Incorrect username or password!'));
        }
    }

    onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    render(){
        const { t } = this.props;
        return (
            <Row className="px-3 h-100" align="middle">
                <Col xs={{span: 24}} sm={{span: 12, offset: 6}}>
                    <Form
                        name="normal_login"
                        className="login-form"
                        initialValues={{
                            username: localStorage.getItem('username'),
                            password: localStorage.getItem('password'),
                            remember: true
                        }}
                        onFinish={(values)=>this.onFinish(values)}
                    >
                        <Form.Item
                            name="username"
                            rules={[{ required: true, message: `${t('Please input', {field: t('Username')})}` }]}
                        >
                            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder={t('Username')} />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: `${t('Please input', {field: t('Password')})}` }]}
                        >
                            <Input.Password
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder={t('Password')}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Form.Item name="remember" valuePropName="checked" noStyle>
                                <Checkbox>{t('Remember me')}</Checkbox>
                            </Form.Item>
                        </Form.Item>

                        <Form.Item className="text-center">
                            <Button type="primary" htmlType="submit" className="">
                                {t('Log in')}
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        );
    }
}
export default actionConnector(withTranslation()(Login), Object.assign(Actions));