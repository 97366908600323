export function getRoute(path){
    const list = {
        '/': 'Home',
        '/orders': 'Orders',
        '/products': 'Products',
        '/login': 'Login Page',
        '/discount': 'Discount'
    }

    return list[path];
}