import {
    LOGIN,
    LOGIN_FAIL,
    LOGOUT,
    START_LOADING,
    STOP_LOADING
} from '../constants/action-types';
const loggedIn = localStorage.getItem('loggedIn');
const initialState = {
    loggedIn: loggedIn == 1 ? true : false,
    loading: false,
    websiteId: 0,
    storeId: 0,
    managerRuleId: 0,
    customerRuleId: 0
};
    
function rootReducer(state = initialState, action) {
    console.log(action);
    switch(action.type){
        case LOGIN:
            return { ...state, loggedIn: true};
        case LOGIN_FAIL:
            return { ...state, loggedIn: false};
        case LOGOUT:
            return { ...state, loggedIn: false};
        case START_LOADING:
            return { ...state, loading: true};
        case STOP_LOADING:
            return { ...state, loading: false};
        default:
            return state;
    }
};
export default rootReducer;