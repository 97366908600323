import React, {Component} from "react";
import { withTranslation } from 'react-i18next';
import {Link, withRouter} from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { LineChartOutlined, ShoppingOutlined, SkinOutlined, PayCircleOutlined } from '@ant-design/icons';

const { Sider } = Layout;

class Sidebar extends Component{
    changeLanguage = (language) => {
        this.props.i18n.changeLanguage(language);
        localStorage.setItem('language', language);
    }

    render(){
        const { t, location } = this.props;
        const pathname = location.pathname == '/login' ? '/' : location.pathname;
        return (
            <Sider
                breakpoint="lg"
                collapsedWidth="0"
                onBreakpoint={broken => {
                    console.log(broken);
                }}
                onCollapse={(collapsed, type) => {
                    console.log(collapsed, type);
                }}
                className="left-sider"
                >
                <div className="logo py-4 text-white text-center">
                    <b>旺猫精选店长后台</b>
                </div>
                <Menu theme="dark" mode="inline" defaultSelectedKeys={[pathname]} className="fix-align-anticon overflow-auto">
                    <Menu.Item key="/" icon={<LineChartOutlined />}>
                        <Link to="/">
                            {t('Home')}
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="/orders" icon={<ShoppingOutlined />}>
                        <Link to="/orders">
                            {t('Orders')}
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="/products" icon={<SkinOutlined />}>
                        <Link to="/products">
                            {t('Products')}
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="/discount" icon={<PayCircleOutlined />}>
                        <Link to="/discount">
                            {t('Discount')}
                        </Link>
                    </Menu.Item>
                </Menu>
            </Sider>
            // <div className="row">
            //     <Link className="navbar-brand href-main" to="/"><b>旺猫精选 - 控制台</b></Link>
            //     <Link className="nav-link href-main"to="/">{t('Home')}</Link>
            //     <Link className="nav-link href-main"to="/orders">{t('Orders')}</Link>
            //     <Link className="nav-link href-main"to="/products">{t('Products')}</Link>
            // </div>
        );
    }
}
export default withRouter(withTranslation()(Sidebar));