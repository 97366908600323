import React, {Component} from "react";
import { withTranslation } from 'react-i18next';
import {Link, withRouter} from 'react-router-dom';
import { Row, Col, Button, Table, Input, Space } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import request from '../utils/request';

class Products extends Component{
    state = {
        searchText: '',
        searchedColumn: '',
        list: [],
        pagination: {
            current: 1,
            pageSize: 10,
            showTotal: (total, range) => `${range[0]}-${range[1]} / ${total}`,
            responsive: true
        },
        loading: false,
        customerDiscount: 0,
        width: window.innerWidth,
        height: window.innerHeight
    };

    componentDidMount(){
        this.setState({
            loading: true
        });
        const url = `rest/V1/salesRules/182`;
        const fetchRule = request(url, 'GET');
        fetchRule.then((response) => {
            console.log(response);
            if('err' in response){
                
            }
            else{
                this.setState({
                    customerDiscount: response.data.discount_amount
                });
                this.updateProductTable(1);
            }
        });
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    updateProductTable = (current) => {
        const url = `rest/V1/products?searchCriteria[filter_groups][0][filters][0][field]=website_id&searchCriteria[filter_groups][0][filters][0][value]=1&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[sortOrders][0][field]=created_at&searchCriteria[pageSize]=10&searchCriteria[currentPage]=${current}`;
        // this.props.loading(true);
        this.setState({
            loading: true
        });
        const fetchProduct = request(url, 'GET');
        fetchProduct.then((response) => {
            if('err' in response){
                
            }
            else{
                var dataItems = [];
                response.data.items.forEach((item)=>{
                    var img = 'https://elvischoice.gz.1258363307.clb.myqcloud.com/pub/media/catalog/product/placeholder/default/placeholder_3.jpg';
                    const customAttributes = item.custom_attributes;
                    var finalPrice = item.price;
                    customAttributes.forEach((customAttribute)=>{
                        if(customAttribute.attribute_code == 'thumbnail'){
                            img = `https://elvischoice.gz.1258363307.clb.myqcloud.com/pub/media/catalog/product${customAttribute.value}`;
                        }
                        if(customAttribute.attribute_code == 'special_price'){
                            finalPrice = customAttribute.value;
                        }
                    });
                    dataItems.push({
                        product_id: item.id,
                        name: item.name,
                        type: item.type_id,
                        price: `HK$${Math.round(finalPrice)}`,
                        sku: item.sku,
                        img: img,
                        manager_price: `HK$${Math.round(finalPrice*0.9)}`,
                        customer_price: `HK$${Math.round(finalPrice*((100-this.state.customerDiscount)/100))}`
                    });
                });
                this.setState(prevState => ({
                    list: dataItems,
                    pagination: {
                        ...prevState.pagination,
                        total: response.data.total_count,
                        current: current
                    }
                }));
            }
            // this.props.loading(false);
            this.setState({
                loading: false
            });
        });
    }

    handleTableChange = (pagination, filters, sorter) => {
        this.updateProductTable(pagination.current);
    };
    
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            this.setState({
                                searchText: selectedKeys[0],
                                searchedColumn: dataIndex,
                            });
                        }}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {this.props.i18n.t('Filter')}
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        {this.props.i18n.t('Reset')}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select(), 100);
          }
        },
        render: text =>
          this.state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[this.state.searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          ),
      });
    
    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };
    
    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    render(){
        const { t } = this.props;
        const columns = [
            {
                title: t('Image'),
                dataIndex: 'img',
                key: 'img',
                render: img => (
                    <img className="table-thumbnail" src={img} />
                )
            },
            {
                title: t('Product Name'),
                dataIndex: 'name',
                key: 'name',
                ...this.getColumnSearchProps('name')
            },
            // {
            //     title: t('Type'),
            //     dataIndex: 'type',
            //     key: 'type',
            //     ...this.getColumnSearchProps('type')
            // },
            {
                title: t('SKU'),
                dataIndex: 'sku',
                key: 'sku',
                ...this.getColumnSearchProps('sku')
            },
            {
                title: t('Price'),
                dataIndex: 'price',
                key: 'price',
                // ...this.getColumnSearchProps('price')
            },
            {
                title: t('Manager Price'),
                dataIndex: 'manager_price',
                key: 'manager_price'
            },
            {
                title: t('Customer Price'),
                dataIndex: 'customer_price',
                key: 'customer_price'
            }
        ];
        return (
            <Row className="p-4 overflow-auto">
                <Col span={24}>
                    <Table className="product-table" loading={this.state.loading} dataSource={this.state.list} columns={columns} pagination={this.state.pagination} tableLayout="fixed" locale={{emptyText:t('No Data')}} onChange={this.handleTableChange} scroll={{ x: this.state.width < 576 ? 576 : null}} />
                </Col>
            </Row>  
        );
    }
}
export default withTranslation()(Products);