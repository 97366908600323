import React, {Component} from "react";
import { withTranslation } from 'react-i18next';
import * as Actions from '../../actions/index';
import actionConnector from '../../utils/actionConnector';
import {Link, withRouter} from 'react-router-dom';
import { Row, Col, Button, Table, Input, PageHeader, Pagination } from 'antd';
import request from '../../utils/request';

class Order extends Component{
    state = {

    };


    render(){
        const { t } = this.props;
        return (
            <>
                <Row className="px-4 pb-4 overflow-auto">
                    <Col span={24}>
                        <PageHeader
                            className=""
                            onBack={() => window.history.back()}
                            title="Title"
                            subTitle="This is a subtitle"
                        />
                    </Col>
                </Row>  
            </>
        );
    }
}
export default actionConnector(withTranslation()(Order), Object.assign(Actions));