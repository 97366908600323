import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

export default function(component, actions)
{
	let mapDispatchToProps = (dispatch) => {
		return bindActionCreators(actions, dispatch);
	}

	let mapStateToProps = (state) => {
		let tmp = { }
		tmp.reducer = state;
		return tmp;
	}

	return connect(mapStateToProps, mapDispatchToProps)(component);
}
