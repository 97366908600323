import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import * as Actions from '../actions/index';
import actionConnector from '../utils/actionConnector';
import { Row, Col, List, Progress, Input, Space } from 'antd';

class Home extends Component{
    render(){
        const { t } = this.props;
        return (
            <Row className="p-4 overflow-auto">
                <Col span={24}>
                    <Row className="">
                        <Col span={12} className="bg-white p-2">
                            <h4>{t('This month sale')}</h4>
                            
                        </Col>
                        <Col span={12} className="bg-white p-2">
                            <h4>{t('This year sale')}</h4>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}
export default actionConnector(withTranslation()(Home), Object.assign(Actions));