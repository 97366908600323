import React, {Component} from 'react';
import logo from './logo.svg';
import Nav from './components/Nav';
import Home from './components/Home';
import Orders from './components/Orders';
import Order from './components/order/Order';
import Products from './components/Products';
import Sidebar from './components/Sidebar';
import Login from './components/Login';
import Discount from './components/Discount';
import Loading from './components/Loading';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import { Layout } from 'antd';
import * as Actions from './actions/index';
import actionConnector from './utils/actionConnector';

class App extends Component{
	render(){
		return (
			<Router>
				{
					this.props.reducer.loading &&
					<Loading></Loading>
				}
				<Switch>
					<Layout>
						<Route exact path="/login">
							{
								this.props.reducer.loggedIn ? 
								<Redirect to='/' /> : 
								<>
									<Nav fullNav={true}></Nav>
									<Layout className="main-layout">
										<Login></Login>
									</Layout>
								</>
							}
						</Route>
						{
							this.props.reducer.loggedIn ? 
							<>
								<Sidebar></Sidebar>
								<Layout className="main-layout margin-left-layout">
									<Nav></Nav>
									<Route exact path="/orders">
										<Orders></Orders>
									</Route>
									<Route exact path="/orders/:id">
										<Order></Order>
									</Route>
									<Route exact path="/products">
										<Products></Products>
									</Route>
									<Route exact path="/discount">
										<Discount></Discount>
									</Route>
									<Route exact path="/">
										<Home></Home>
									</Route>
								</Layout>
							</> :
							<Redirect to='/login' />
						}
					</Layout>
				</Switch>
			</Router>
		);
	}
}

// export default App;
export default actionConnector(App, Object.assign(Actions));