import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import * as Actions from '../actions/index';
import actionConnector from '../utils/actionConnector';
import { Row, Col, List, Select, Input, Spin, message } from 'antd';
import request from '../utils/request';

const { Option } = Select;

class Home extends Component{
    state = {
        customerDiscount: null,
        loading: false
    }

    componentDidMount(){
        this.updateList();
    }

    updateList = () => {
        this.setState({
            loading: true
        });
        const url = `rest/V1/salesRules/182`;
        const fetchRule = request(url, 'GET');
        fetchRule.then((response) => {
            console.log(response);
            if('err' in response){
                
            }
            else{
                this.setState({
                    customerDiscount: response.data.discount_amount
                });
            }
            this.setState({
                loading: false
            });
        });
    }

    handleTableChange = (value) => {
        console.log(value);
        this.setState({
            loading: true
        });
        const url = `rest/V1/salesRules/182`;
        const data = {
            rule: {
                discount_amount: value
            }
        };
        const fetchOrder = request(url, 'PUT', data);
        fetchOrder.then((response) => {
            console.log(response);
            if('err' in response){
                message.error(this.props.i18n.t('Update fail!'));
            }
            else{
                this.setState({
                    customerDiscount: value
                });
                message.success(this.props.i18n.t('Update success!'));
            }
            this.setState({
                loading: false
            });
        });
    };

    renderActions = (item) => {
        switch(item.code){
            case 'manager_discount':
                return [
                    <span>10%</span>
                ];
            case 'customer_discount':
                return [
                    <Spin spinning={this.state.loading}>
                        <Select value={this.state.customerDiscount} onChange={value => this.handleTableChange(value)} style={{ width: 80 }}>
                            <Option value={5}>5%</Option>
                            <Option value={6}>6%</Option>
                            <Option value={7}>7%</Option>
                        </Select>
                    </Spin>
                ];
        }
        return [<a key="list-loadmore-edit">edit</a>, <a key="list-loadmore-more">more</a>];
    }

    render(){
        const { t } = this.props;
        const list = [
            {code: 'manager_discount', name: t('Shop Manager Discount'), value: 10},
            {code: 'customer_discount', name: t('Customer Discount'), value: 10}
        ];
        return (
            <Row className="p-4 overflow-auto">
                    <Col xs={{span:24}} sm={{span:12}}>
                        <List
                        className="bg-white px-3 py-2"
                        itemLayout="horizontal"
                        dataSource={list}
                        renderItem={item => (
                            <List.Item
                                actions={
                                    this.renderActions(item)
                                }
                            >
                                <List.Item.Meta
                                    title={<span>{item.name}</span>}
                                    description="这是说明"
                                />
                            </List.Item>
                        )}
                    />
                </Col>
            </Row>
        );
    }
}
export default actionConnector(withTranslation()(Home), Object.assign(Actions));