import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import * as Actions from '../actions/index';
import actionConnector from '../utils/actionConnector';
import { Spin, Row } from 'antd';

class Loading extends Component{
    render(){
        const { t } = this.props;
        return (
            <div className="loading-layer text-center">
                <Spin size="large" />
            </div>
        );
    }
}
export default actionConnector(withTranslation()(Loading), Object.assign(Actions));