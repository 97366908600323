import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

const lng = localStorage.getItem('language') ? localStorage.getItem('language') : 'zh-cn';

i18n
    .use(Backend) 
    .use(initReactI18next)  
    .init({
        backend: {
            loadPath: "/locales/{{lng}}/{{ns}}.json"
        },
        fallbackLng: lng,
        lng: lng,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;